import React from "react";
import { useHistory } from "react-router-dom";

import chevronsLeft from "../../assets/img/icons/streamline-icon-navigation-arrows-left.png";

function ZurueckButton() {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="inline-flex items-center text-lg border-b text-zf_red border-zf_red"
    >
      <img src={chevronsLeft} alt="back" className="h-4" />
      <span className="ml-2">Zurück</span>
    </button>
  );
}

export default ZurueckButton;
