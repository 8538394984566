import { SHA3 } from "sha3"
import { PrismaSDK } from "@prismadelabs/prismaid"
import DeviceUtils from "./DeviceUtils"

export class DataBuilder {
    private window: any = undefined
    private customPayload: any = null
    private touchData: any[] = []
    private isCheckSumAndSignatureEnabled: boolean = false

    constructor(private sdk: PrismaSDK) {
        this.window = window
    }

    public setWindow(window: any): DataBuilder {
        this.window = window
        return this
    }

    public setTouchData(touchData: any[]): DataBuilder {
        const pageZoom = DeviceUtils.pageZoomFactor()
        if (pageZoom !== 1) {
            touchData.forEach((touch) => {
                touch.x = touch.x * pageZoom
                touch.y = touch.y * pageZoom
            })
        }
        this.touchData = touchData
        return this
    }

    public setCustomPayload(customPayload: any): DataBuilder {
        this.customPayload = customPayload
        return this
    }

    public enableCheckSumAndSignature(): DataBuilder {
        this.isCheckSumAndSignatureEnabled = true
        return this
    }

    public buildJSON(): any {
        let signature = ""
        let checksum = ""

        if (this.isCheckSumAndSignatureEnabled) {
            signature = this.sdk.getSessionToken()
            checksum = new SHA3(512).update(JSON.stringify(this.touchData) + signature).digest("hex")
        }
        const userAgent = navigator.userAgent

        const data = {
            checksum,
            customData: this.customPayload,
            deviceInfo: {
                appType: this.sdk.getAppType(),
                gpuRenderer: DeviceUtils.gpuRenderer(),
                // FIXME: disabled because of gpu.js vs. stencils/ionic bundling issue
                // gpuScore: DeviceUtils.gpuScore(),
                manufacturer: DeviceUtils.manufacturer(),
                model: DeviceUtils.iOSModel(),
                userAgent,
            },
            deviceOS: {
                osname: DeviceUtils.oSName(),
                version: DeviceUtils.oSVersion(),
            },
            deviceScreen: {
                coordinatesUnit: this.sdk.getCoordinatesUnit(),
                devicePixelRatio: this.window.devicePixelRatio,
                height: this.window.screen.height,
                width: this.window.screen.width,
            },
            installationID: this.sdk.getUUID(),
            sdkVersion: "z-special-0.0.1",
            signature,
            touchData: this.touchData,
        }

        return data
    }

    public buildJSONStringify(): string {
        return JSON.stringify(this.buildJSON())
    }
}
