import React from "react";

// components
import { Helmet } from "react-helmet";

// images
import logo_black from "../assets/img/logos/logo_speakuplounge_black.png";
import ZurueckButton from "../components/buttons/ZurueckButton";

//
function Kontakt() {
  return (
    <div>
      <Helmet>
        <title>Kontakt | Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <body className="bg-zf_gray-light"></body>
      </Helmet>
      <div className="flex justify-between pt-8">
        <div className="self-center pl-8">
          <a href="/">
            <img
              src={logo_black}
              alt="Logo SpeakupLounge - white"
              className="h-8"
            />
          </a>
        </div>
      </div>

      <div className="px-8 pt-8">
        <ZurueckButton />
      </div>

      <div className="px-8 pt-10 pb-10 text-gray-900">
        <h1 className="text-5xl font-medium">Kontakt</h1>

        <address className="text-xl not-italic font-light">
          <h3 className="sr-only">Address</h3>
          <p className="pt-4 pb-4">
            Speak Up Lounge
            <br />
            Bahnhofsstraße 51
            <br />
            09111 Chemnitz
          </p>

          <h3 className="inline-block">Telefon:</h3>
          <a href="tel:0371-997500" className="px-2">
            0371 99-7500
          </a>
          <br />

          <h3 className="inline-block">E-Mail:</h3>
          <a href="mailto:hallo@speakuplounge.de" className="sm:px-2">
            hallo@speakuplounge.de
          </a>
        </address>
      </div>
    </div>
  );
}

export default Kontakt;
