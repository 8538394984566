import React from "react";

// components
import { Helmet } from "react-helmet";

// images
import logo_black from "../assets/img/logos/logo_speakuplounge_black.png";

import ZurueckButton from "../components/buttons/ZurueckButton";

//
function Impressum() {
  return (
    <div>
      <Helmet>
        <title>Impressum | Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <body className="bg-zf_gray-light"></body>
      </Helmet>
      <div className="flex justify-between pt-8">
        <div className="self-center pl-8">
          <a href="/">
            <img
              src={logo_black}
              alt="Logo SpeakupLounge - white"
              className="h-8"
            />
          </a>
        </div>
      </div>

      <div className="px-8 pt-8">
        <ZurueckButton />
      </div>

      <div className="px-8 pt-10 pb-10 text-gray-900">
        <h1 className="text-5xl font-medium">Impressum</h1>

        <address className="pt-4 text-xl not-italic font-light">
          <h3 className="sr-only">Address</h3>
          <p className="pt-4">
            zweigfabrik GmbH
            <br />
            Innovation Lab der Sparkasse{"\u00A0"}Chemnitz
            <br />
            Moritzstraße 24
            <br />
            09111 Chemnitz
          </p>

          <div className="mt-8">
            <h3 className="inline-block">Telefon:</h3>
            <a href="tel:0371-997500" className="px-2">
              0371 99-7500
            </a>
          </div>

          <div className="">
            <h3 className="inline-block">E-Mail:</h3>
            <a href="mailto:hallo@zweigfabrik.de" className="px-2 underline">
              hallo@zweigfabrik.de
            </a>
            <br />
          </div>

          <div className="">
            <h3 className="sr-only">Webseite:</h3>
            <a href="https://www.zweigfabrik.de" className="underline ">
              www.zweigfabrik.de
            </a>
          </div>
        </address>

        <div className="mt-8 text-xl not-italic font-light">
          <h3 className="inline-block">Handelsregister:</h3>
          <span className="px-2">HBR 27670, Amtsgericht Chemnitz</span>
        </div>

        <div className="mt-2 text-xl not-italic font-light sm:mt-0">
          <h3 className="inline-block">Umsatzsteuer-Identifikationsnummer:</h3>
          <span className="sm:px-2">DE 285576570</span>
        </div>

        <div className="mt-8 text-xl not-italic font-light">
          <h3 className="inline-block">Geschäftsführer:</h3>
          <span className="sm:px-2">
            Tanja{"\u00A0"}Höhne,{"\u00A0"}Enrico{"\u00A0"}Langer
          </span>
        </div>
      </div>
    </div>
  );
}

export default Impressum;
