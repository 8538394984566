
export default class DeviceUtils {
    public static isIOS(): boolean {
        return /iPad|iPhone|iPod|Intel Mac/.test(navigator.userAgent)
    }

    public static isIPad() {
        return /iPad|Intel Mac/.test(navigator.userAgent)
    }

    public static isIPhone() {
        return /iPhone/.test(navigator.userAgent)
    }

    public static isIPod() {
        return /iPod/.test(navigator.userAgent)
    }

    public static iOSModel() {
        if (this.isIPad()) {
            return "iPad"
        }
        if (this.isIPhone()) {
            return "iPhone"
        }
        if (this.isIPod()) {
            return "iPod"
        }
        return ""
    }

    public static iOSVersion() {
        return navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
    }

    public static manufacturer() {
        return this.isIOS() ? "Apple" : ""
    }

    public static oSName() {
        if (this.isIOS()) {
            return "iOS"
        }
        if (/android/i.test(navigator.userAgent)) {
            return "Android"
        }
        return ""
    }

    public static oSVersion() {
        return this.isIOS() ? this.iOSVersion() : ""
    }

    public static gpuRenderer() {
        if (this.gpu !== "") {
            return this.gpu
        }
        const canvas = document.createElement("canvas")
        if (canvas) {
            const context = (canvas.getContext("webgl") || canvas.getContext("experimental-webgl")) as WebGLRenderingContext
            if (context) {
                const info = context.getExtension("WEBGL_debug_renderer_info")
                if (info) {
                    this.gpu = context.getParameter(info.UNMASKED_RENDERER_WEBGL)
                    this.loseContext(context)
                    return this.gpu
                }
                this.loseContext(context)
            }
        }
        return "unknown"
    }

    public static pageZoomFactor(): number {
        if (!this.isIOS()) {
            return 1
        }
        if (document.documentElement.clientWidth === 0) {
            return 1
        }
        const pageZoom = window.outerWidth / document.documentElement.clientWidth
        return pageZoom ? pageZoom : 1
    }

    private static gpu: string = ""
    private static webglfp: string
    private static gpuscore: number

    private static loseContext(context: WebGLRenderingContext) {
        const ctx = context.getExtension("WEBGL_lose_context")
        if (ctx) {
            ctx.loseContext()
        }
    }
}
