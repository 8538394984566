import React from "react";

// components
import BigFooter from "../components/BigFooter";
import { Helmet } from "react-helmet";

// images
import logo_white from "../assets/img/logos/logo_speakuplounge_white.png";
import hero_image from "../assets/img/hero-image.jpg";
import calendar from "../assets/img/icons/streamline-icon-calendar-download.png";
import linie from "../assets/img/icons/linie.png";
import icsFile from "../assets/files/speakuploung_vip.ics";

//
function Verify() {
  return (
    <div>
      <Helmet>
        <title>Verify | Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="bg-zf_red">
        <div
          className="bg-center bg-cover"
          style={{ backgroundImage: `url(${hero_image})` }}
        >
          <div className="flex justify-between pt-8">
            <div className="self-center pl-8">
              <a href="/">
                <img
                  src={logo_white}
                  alt="Logo SpeakupLounge - white"
                  className="h-8"
                />
              </a>
            </div>
          </div>
          <h1 className="px-8 pt-24 pb-32 text-white">
            <div className="inline-flex items-center text-sm font-semibold uppercase">
              Die Zukunft ist
              <img src={linie} alt="" className="ml-4" />
            </div>
            <div className="pt-4 text-5xl font-medium leading-none">
              näher als
              <br />
              Sie denken
            </div>
          </h1>
        </div>

        <div className="px-8 mt-16 text-white">
          <h2 className="text-2xl font-bold">
            Einladung zum VIP&#8209;Opening
            <br />
            der Speak{"\u00A0"}up{"\u00A0"}Lounge
          </h2>
          <p className="mt-8 text-lg font-light ">
            <span className="font-semibold">Wo:</span> Speak{"\u00A0"}up
            {"\u00A0"}Lounge, Bahnhofsstr.{"\u00A0"}51, 09111{"\u00A0"}Chemnitz
          </p>
          <p className="pt-4 text-lg font-light ">
            <span className="font-semibold">Wann:</span> Mittwoch, 4.{"\u00A0"}
            November{"\u00A0"}2020, 14-16{"\u00A0"}
            Uhr
          </p>

          <h2 className="mt-10 text-lg font-bold">
            Entdecken Sie unsere Speak{"\u00A0"}up{"\u00A0"}Lounge
          </h2>
          <p className="pt-10 text-lg font-light">
            Unser neues Test&#8209; und Experimentierlabor ist ein Raum zum
            Diskutieren und Erleben von neuen Services und Technologien. Hier
            verbinden wir die analoge und digitale Welt und entwickeln Ideen und
            Lösungen für die Zukunft.
          </p>
          <p className="pt-4 text-lg font-light">
            Genießen Sie zum VIP&#8209;Opening Einblicke in die Technologie
            hinter dieser Einladung.
          </p>

          <p className="pt-4 text-lg font-light">
            Für kulinarische Stärkung ist gesorgt.
          </p>

          <p className="pt-10 text-lg font-light ">
            Bitte melden Sie sich bis zum 9.{"\u00A0"}Oktober{"\u00A0"}2020 per
            WhatsApp{" "}
            <a
              href="https://api.whatsapp.com/send?phone=4915780535780&text=Liebes%20zweigfabrik-Team%2C%20gern%20sage%20ich%20Ihnen%20hiermit%20meine%20Teilnahme%20zur%20Er%C3%B6ffnung%20der%20Speak%20up%20Lounge%20am%2004.%20November%2014-16%20Uhr%20zu"
              className="underline"
            >
              0157{"\u00A0"}80535780
            </a>{" "}
            oder per E&#8209;Mail{" "}
            <a
              href="mailto:hallo@speakuplounge.de?subject=Zusage%20zur%20Teilnahme%20an%20der%20Er%C3%B6ffnung%20der%20Speak%20up%20Lounge&body=Liebes%20zweigfabrik-Team%2C%20gern%20sage%20ich%20Ihnen%20hiermit%20meine%20Teilnahme%20zur%20Er%C3%B6ffnung%20der%20Speak%20up%20Lounge%20am%2004.%20November%2014-16%20Uhr%20zu"
              className="underline"
            >
              hallo@speakuplounge.de
            </a>{" "}
            an
          </p>
          <p className="pt-4 text-lg font-light ">Wir freuen uns auf Sie!</p>
          <p className="pt-4 text-lg font-light ">
            Herzliche Grüße
            <br />
            Tanja{"\u00A0"}Höhne und Enrico{"\u00A0"}Langer
          </p>
        </div>

        <div className="pb-24 mt-6">
          <a
            href={icsFile}
            className="flex items-center justify-center py-3 mx-8 bg-white rounded-full text-zf_red"
          >
            <img src={calendar} alt="calendar" className="h-6" />
            <span className="ml-2 ">Termin speichern</span>
          </a>
        </div>
      </div>

      <BigFooter />
    </div>
  );
}

export default Verify;
