import React from "react";

//
type CircleProps = {
  radius: number;
  id: string;
  color: string;
};

export class Circle extends React.Component<CircleProps, {}> {
  render() {
    return (
      <svg
        width={this.props.radius * 2}
        height={this.props.radius * 2}
        id={this.props.id}
        className="absolute object-none object-left-top max-w-none origin-center animatable"
        style={{
          opacity: 0,
        }}
      >
        <circle
          cx={this.props.radius}
          cy={this.props.radius}
          r={this.props.radius}
          fill={this.props.color}
          scale="1"
        />
      </svg>
    );
  }
}
