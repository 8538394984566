import React from "react";
import anime from "animejs";

// images
import background from "../../assets/img/button_background_red.svg";

//
class ClickToStart extends React.Component {
  componentDidMount() {
    var button = document.getElementById("startButton");
    if (button === null) return;

    button.style.backgroundImage = "url(" + background + ")";
    button.style.backgroundPosition = "center";
    button.style.backgroundSize = "contain";
    button.style.backgroundRepeat = "no-repeat";

    anime({
      targets: "#startButton",
      scale: 1.1,
      easing: "linear",
      loop: true,
      direction: "alternate",
      duration: 1000,
    });
  }

  render() {
    return (
      <div className="px-0">
        <a href="/swipe">
          <div className="relative flex content-center justify-center h-40 mx-auto w-84">
            <div
              id="startButton"
              className="absolute self-center h-32 w-72"
            ></div>

            <div
              id="title"
              className="absolute inset-auto self-center w-56 text-base leading-none text-center "
            >
              <p>Hier starten</p>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default ClickToStart;
