/**
 * x
 */
export function alignHorizontally(
  elementId: string,
  alignment: string,
  offset?: number
) {
  const element = document.getElementById(elementId);
  if (!element) return;

  element.style.left = calculatePositionX(elementId, alignment, offset) + "px";
}

export function alignVertically(
  elementId: string,
  alignment: string,
  offset?: number
) {
  const element = document.getElementById(elementId);
  if (!element) return;

  element.style.top = calculatePositionY(elementId, alignment, offset) + "px";
}

export function calculatePositionX(
  elementId: string,
  alignment: string,
  offset?: number
) {
  const element = document.getElementById(elementId);
  if (!element) return;

  var x = 0;
  offset = offset ?? 0;

  switch (alignment) {
    case "left":
      x = 0 + offset;
      break;
    case "center":
      x = getHorizontalCenter()! - getHorizontalCenter(elementId)! + offset;
      break;
    case "right":
      x = getWidth()! - getWidth(elementId)! + offset;
      break;
    default:
  }
  return x;
}

// calculate positions
export function calculatePositionY(
  elementId: string,
  alignment: string,
  offset?: number
) {
  const element = document.getElementById(elementId);
  if (!element) return;

  var y = 0;
  offset = offset ?? 0;

  switch (alignment) {
    case "top":
      y = 0 + offset;
      break;
    case "center":
      y = getVerticalCenter()! - getVerticalCenter(elementId)! + offset;
      break;
    case "bottom":
      y = getHeight()! - getHeight(elementId)! + offset;
      break;
    default:
  }
  return y;
}

/**
 * helper
 */
export function getWidth(elementId = "swipeScreen") {
  const element = document.getElementById(elementId);
  if (!element) return;

  var width = element.getBoundingClientRect().width;
  return width;
}

export function getHeight(elementId = "swipeScreen") {
  const element = document.getElementById(elementId);
  if (!element) return;

  var height;
  if (elementId === "swipeScreen") {
    height = window.innerHeight;
  } else {
    height = element.getBoundingClientRect().height;
  }

  return height;
}

function getHorizontalCenter(elementId = "swipeScreen") {
  const element = document.getElementById(elementId);
  if (!element) return;

  var center_x = getWidth(elementId)! / 2;
  return center_x;
}

function getVerticalCenter(elementId = "swipeScreen") {
  const element = document.getElementById(elementId);
  if (!element) return;

  var center_y = getHeight(elementId)! / 2.0;
  return center_y;
}
