export enum TouchEventType {
    start,
    end,
    move,
    cancel,
}

export class TouchEntry {
    public id: number
    public event: TouchEventType
    public x: number
    public y: number
    public radius: number
    public timestamp: number

    constructor(id: number, event: TouchEventType, x: number, y: number, radius: number, timestamp: number) {
        this.id = id
        this.event = event
        this.x = x
        this.y = y
        this.radius = radius
        this.timestamp = timestamp
    }
}