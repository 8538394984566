import React from "react";

// components
import { Helmet } from "react-helmet";

//
function NotFound() {
  return (
    <div className="NotFound">
      <Helmet>
        <title>Not Found | Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <h1>NotFound</h1>
    </div>
  );
}

export default NotFound;
