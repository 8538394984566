import React from "react";
// TODO @types
import DeviceOrientation, { Orientation } from "react-screen-orientation";

// components
import { Helmet } from "react-helmet";
import ZScreen from "../components/ZScreen";
import NotSupportedModal from "../components/modals/NotSupportedModal";
import BackIconButton from "../components/buttons/BackIconButton";

// functions
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// images
import rotateDevice from "../assets/img/alerts/rotate-device.png";

//
type SwipeProps = {};
type SwipeStates = {
  orientation: string;
  deviceIsSupported: boolean;
  browserIsSupported: boolean;
};

class Swipe extends React.Component<SwipeProps, SwipeStates> {
  swipeElement!: HTMLElement;
  overlay!: HTMLElement;
  click: any;

  constructor(props: SwipeProps) {
    super(props);

    this.state = {
      orientation: "portrait",
      //   isSupported: true,
      deviceIsSupported: true,
      browserIsSupported: true,
    };
  }

  componentDidMount() {
    this.swipeElement = document.querySelector<HTMLElement>("#swipe")!;
    disableBodyScroll(this.swipeElement);

    this.overlay = document.querySelector<HTMLElement>("#overlay")!;
    disableBodyScroll(this.overlay);

    this.overlay = document.querySelector<HTMLElement>("#message")!;
    disableBodyScroll(this.overlay);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  clickTemp = (click: any) => {
    this.clickTemp = click;
  };

  setDeviceIsSupported = (deviceIsSupported: boolean) => {
    this.setState({ deviceIsSupported: deviceIsSupported });
  };

  setBrowserIsSupported = (browserIsSupported: boolean) => {
    this.setState({ browserIsSupported: browserIsSupported });
  };

  showNotSupportedModal = (): boolean => {
    if (!this.state.deviceIsSupported) return true;
    if (!this.state.browserIsSupported) return true;

    return false;
  };

  render() {
    return (
      <DeviceOrientation
        lockOrientation={"portrait"}
        onLockOrientation={(success: any) => {}}
        onOrientationChange={(orientation: any) => {
          this.setState({ orientation: orientation });
          console.log("device orientation changed to: ", orientation);
        }}
      >
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <div id="swipe">
            <Helmet>
              <title>Swipe | Zweigfabrik</title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
            </Helmet>

            <ZScreen
              setTemp={this.clickTemp}
              setDeviceSupport={this.setDeviceIsSupported}
              setBrowserSupport={this.setBrowserIsSupported}
              orientation={this.state.orientation}
            />

            <div
              id="message"
              className="absolute top-0 w-screen pt-6 mt-8 text-base font-semibold text-center text-zf_red"
              style={{ touchAction: "none" }}
            >
              <div
                className="p-1 m-2 mx-auto bg-white bg-opacity-50 rounded"
                style={{ touchAction: "none" }}
              >
                Siegel auf Display legen, mit dem Finger{" "}
                <span className="font-bold">langsam</span> das Z nachzeichnen.
              </div>
            </div>
          </div>

          <div
            id="overlay"
            className="absolute top-0 flex justify-between w-screen px-8 py-6"
            style={{ touchAction: "none" }}
          >
            <BackIconButton />

            {this.showNotSupportedModal() ? (
              <NotSupportedModal
                deviceIsSupported={this.state.deviceIsSupported}
                browserIsSupported={this.state.browserIsSupported}
              />
            ) : null}
          </div>
        </Orientation>

        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <div className="flex content-center justify-center w-full h-screen">
            <img
              src={rotateDevice}
              alt="Please rotate your device."
              className="m-auto max-h-1/2-screen"
            />
          </div>
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export default Swipe;
