import React from "react";

// images
import unsupportedDevice from "../../assets/img/alerts/unsupported-device.png";
import unsupportedBrowser from "../../assets/img/alerts/unsupported-browser.png";

//
type NotSupportedModalProps = {
  deviceIsSupported: boolean;
  browserIsSupported: boolean;
};
type NotSupportedModalStates = {
  showModal: boolean;
  message: string;
  alt: string;
  img: any;
};

class NotSupportedModal extends React.Component<
  NotSupportedModalProps,
  NotSupportedModalStates
> {
  constructor(props: NotSupportedModalProps) {
    super(props);

    let message: string;
    let alt: string;
    let img;

    if (!this.props.browserIsSupported && this.props.deviceIsSupported) {
      message =
        "Browser wird nicht unterstützt.\n\nEventuell funktioniert die Erkennung nicht richtig.";
      alt = "browser not supported";
      img = unsupportedBrowser;
    } else if (this.props.browserIsSupported && !this.props.deviceIsSupported) {
      message =
        "Gerät wird nicht unterstützt.\n\nEventuell funktioniert die Erkennung nicht richtig.";
      alt = "device not supported";
      img = unsupportedDevice;
    } else {
      message =
        "Gerät oder Browser werden nicht unterstützt.\n\nEventuell funktioniert die Erkennung nicht richtig.";
      alt = "device or browser not supported";
      img = unsupportedDevice;
    }

    this.state = {
      showModal: true,
      message: message,
      alt: alt,
      img: img,
    };
  }

  render() {
    return (
      <>
        {this.state.showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              onClick={() => this.setState({ showModal: false })}
            >
              <div className="relative w-auto my-6 mx-auto max-w-sm">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <img
                      src={this.state.img}
                      alt={this.state.alt}
                      className="h-40 mx-auto mt-4"
                    ></img>
                    <p
                      className="mx-auto mt-8 mb-2 text-gray-600 text-lg text-center"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {this.state.message}
                    </p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-center p-6 border-t border-solid border-gray-300 rounded-b">
                    <button
                      className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      onClick={() => {
                        this.setState({ showModal: false });
                        console.log("*) close modal");
                      }}
                    >
                      Verstanden
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }
}

export default NotSupportedModal;
