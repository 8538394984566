import React from "react";

//
type ScaledImageProps = {
  src: string;
  id: string;
  alt: string;
  scaleFactor: number;
};
type ScaledImageStates = {
  width: number;
};

class ScaledImage extends React.Component<ScaledImageProps, ScaledImageStates> {
  constructor(props: ScaledImageProps) {
    super(props);

    this.state = {
      width: 0,
    };
  }

  componentDidUpdate() {
    let img = new Image();
    img.src = this.props.src;
    img.onload = () => {
      this.setState({
        width: img.width,
      });
    };
  }

  render() {
    return (
      <img
        src={this.props.src}
        id={this.props.id}
        alt={this.props.alt}
        className="absolute object-left-top select-none max-w-none animatable"
        style={{
          opacity: 0,
          width: this.state.width * this.props.scaleFactor,
          top: 0,
          left: 0,
        }}
      />
    );
  }
}

export default ScaledImage;
