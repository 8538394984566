import React from "react";
import queryString from "query-string";

// components
import BigFooter from "../components/BigFooter";
import { Helmet } from "react-helmet";
import ClickToStart from "../components/buttons/ClickToStart";

// images
import logo_white from "../assets/img/logos/logo_speakuplounge_white.png";
import hero_image from "../assets/img/hero-image.jpg";
import hello from "../assets/img/hello.svg";

type Greeting = {
  id: string;
  name: string;
};

function Home() {
  var names: Greeting[];

  names = [
    { id: "a46h", name: "Dr. Michael Kreuzkamp" },
    { id: "kb36", name: "Torsten Wetzel" },
    { id: "vc19", name: "Anne Rentsch" },
    { id: "wi87", name: "Sven Mücklich" },
    { id: "dp21", name: "Verena Heinze" },
    { id: "xv55", name: "Frieder Schreier" },
    { id: "k7h5", name: "Kerstin Loos" },
    { id: "c3o9", name: "Danny Six" },
    { id: "x3q1", name: "Jens Weichert" },
    { id: "b87h", name: "Grit Joseph" },
    { id: "p7g6", name: "André Gersch" },
    { id: "q34j", name: "Heike Fischer" },
    { id: "r68i", name: "Marko Seifert" },
    { id: "mnf6", name: "Kerstin Sterzel" },
    { id: "fki2", name: "Erik Anders" },
    { id: "id5t", name: "Katrin Dörffel" },
    { id: "xvg5", name: "Manuela Seiler" },
    { id: "lkt4", name: "Carmen Münster" },
    { id: "oic6", name: "Ivo Harzdorf" },
    { id: "gto7", name: "Andreas Wank" },
    { id: "zv7h", name: "André Rietze" },
    { id: "etn5", name: "Thomas Schumann" },
    { id: "fd7u", name: "Dr. Nicole Lorenz" },
    { id: "7nn4", name: "Falk Wilhelm" },
    { id: "tgg5", name: "Tanja Höhne" },
  ];

  let greeting = "";
  let result;

  const parsed = queryString.parse(window.location.search);

  if (parsed.id) {
    result = names.find((name) => name.id === parsed.id);
    if (result) {
      greeting = result.name;
    }
  }

  return (
    <div>
      <Helmet>
        <title>Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div
        className="min-h-screen bg-center bg-cover"
        style={{ backgroundImage: `url(${hero_image})` }}
      >
        <div id="header" className="flex justify-between pt-8">
          <div className="self-center pl-8">
            <a href="/">
              <img
                src={logo_white}
                alt="Logo SpeakupLounge - white"
                className="h-8"
              />
            </a>
          </div>
        </div>

        <div className="pt-16 text-white">
          <h1 className="px-8 text-4xl font-medium leading-none">
            <img src={hello} alt="Hallo" className="h-24 mb-3" />
            {greeting}
            {greeting ? "," : null}
          </h1>
          <p className="px-8 pt-8 text-xl font-light leading-snug">
            herzlich willkommen zum VIP&#8209;Opening der Speak{"\u00A0"}up
            {"\u00A0"}
            Lounge.
          </p>
          <p className="px-8 pt-6 text-xl font-light leading-snug">
            Halten Sie das Siegel aus Ihrer analogen Einladung bereit und
            klicken Sie den nachfolgenden Button.
          </p>
          <div className="pt-10 pb-10">
            <ClickToStart />
          </div>
        </div>
      </div>

      <BigFooter />
    </div>
  );
}

export default Home;
