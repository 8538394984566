import React from "react";
import { useHistory } from "react-router-dom";

// image
import icon from "../../assets/img/swipe/scan_back-button.png";
//
function BackIconButton() {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <button type="button" onClick={handleClick}>
      <div className="inline-flex items-center">
        <img src={icon} id="back" alt="Zurück" className="h-8" />
        <span className="ml-2 text-lg text-zf_red">Zurück</span>
      </div>
    </button>
  );
}

BackIconButton.defaultProps = {
  title: "Zurück",
};

export default BackIconButton;
