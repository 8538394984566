import React from "react";

// images
import logo_red from "../assets/img/logos/logo_speakuplounge_red.png";
import externalLink from "../assets/img/icons//streamline-icon-external-link-6-alternate.png";

//
function BigFooter() {
  return (
    <div id="footer" className="text-gray-900 bg-white">
      <h2 className="px-8 pt-12 text-2xl font-bold leading-tight">
        Wie entsteht Zukunft?
      </h2>
      <p className="px-8 mt-4 text-lg font-light">
        Die Speak up Lounge ist ein Raum zum Diskutieren, Testen und
        Experimentieren. Hier entwickeln wir neue Ideen, arbeiten Lösungen aus,
        erproben und skalieren sie. In der Speak{"\u00A0"}up{"\u00A0"}Lounge
        wird Zukunft erlebbar.
      </p>
      <div className="px-8 mt-4">
        <a href="https://zweigfabrik.de/ausprobieren">
          <div className="inline-flex items-center text-lg border-b text-zf_red border-zf_red">
            <img src={externalLink} alt="external link" className="h-4" />
            <span className="ml-2">Prototypen anschauen</span>
          </div>
        </a>
      </div>

      <h2 className="px-8 pt-12 text-2xl font-bold leading-tight">
        Siegel zum Eintritt verloren?
      </h2>
      <p className="px-8 pt-12 text-xl font-bold leading-tight">
        Schreiben Sie uns:
      </p>

      <address className="not-italic">
        <a href="mailto:hallo@speakuplounge.de">
          <p className="px-8 text-lg font-light underline">
            hallo@speakuplounge.de
          </p>
        </a>
      </address>

      <div className="mx-8 mt-12 border-t-2 border-black"></div>
      <div className="flex justify-between px-8 pt-4 text-lg font-light">
        <a href="/impressum">Impressum</a>
        <a href="/kontakt">Kontakt</a>
      </div>

      <p className="px-8 pt-4 text-lg font-light">
        &copy; 2020 Speak up Lounge
      </p>
      <div className="flex justify-center pt-12 pb-4">
        <img src={logo_red} className="h-10" alt="SpeakupLounge Logo - red" />
      </div>
    </div>
  );
}

export default BigFooter;
